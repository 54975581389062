<template>
  <div>
    <b-card header-tag="header" footer-tag="footer" style="height: 750px;">
      <b-nav tabs>
        <b-nav-item>
          <router-link :to="'/system-traffic'" :exact="true">Sistem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/request-log'" :exact="true">İstem trafiği </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link :to="'/networkholter'" :exact="true">Network Holter </router-link>
        </b-nav-item>
        <b-nav-item active v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/merge-patient'" :exact="true">Hasta Birleştirme </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/database-document-management'" :exact="true">Veritabanı Döküman Yönetimi </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
          <router-link :to="'/customer-support'" :exact="true"> Müşteri Destek </router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/system-management'" :exact="true">WisdomEra Sistem Yönetimi</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1)">
          <router-link :to="'/data-structure-analyze'" :exact="true">Veri Yapısı Analizi</router-link>
        </b-nav-item>
      </b-nav>
      <b-card style="margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
          Hasta Birleştirme İşlemi
        </b-card-header>
        <b-row>
          <b-col cols="12">
            Lütfen yapacağınız işlemde çok dikkatli olunuz. Bu işlemin geri dönüş imkanı bulunmamaktadır
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card style="margin-bottom: 0px;" no-body>
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Seçilen Hastalar
                <b-button class="pull-right" variant="primary" @click="f_mergePatients()"><i class="fa fa-search"></i> hastaları birleştir</b-button>
              </b-card-header>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <b-card style="margin-bottom: 0px;" no-body>
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                        FROM (Kaynak) Hasta
                      </b-card-header>
                      <b-col cols="12">
                        <b-row v-for="info, ind in d_fromPatient" :key="'d_fromPatient_' + ind">
                          <strong>{{ ind }}: </strong> {{ info }}
                        </b-row>
                      </b-col>
                    </b-card>
                  </b-col>
                  <b-col cols="6">
                    <b-card style="margin-bottom: 0px;" no-body>
                      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                        TO (Hedef) Hasta
                      </b-card-header>
                      <b-col cols="12">
                        <b-row v-for="info, ind in d_toPatient" :key="'d_toPatient_' + ind">
                          <strong>{{ ind }}: </strong> {{ info }}
                        </b-row>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card style="margin-bottom: 0px;" no-body>
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Birleştirilecek Hasta Seçim İşlemi
              </b-card-header>
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <b-form-select :plain="true" v-model="d_hospitalGroup" @change="f_changeHospitalGroup()" required>
                      <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="6">
                    <b-form-select :plain="true" v-model="d_hospitalId" required>
                      <!-- <b-form-select :plain="true" v-model="d_hospitalId" @change="f_changeHospitalId()" required> -->
                      <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="" label-for="search_patient">
                      <b-input-group>
                        <b-form-input id="search_patient" placeholder="Hasta Ad Soyad, WisdomEra hasta no, Medin Hasta No, Tc/Passport no ile arama yapabilirsiniz" type="text" v-model="d_searchText" @keydown.enter.native="ClickEnter"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="success" @click="f_searchPatient()"><i class="fa fa-search"></i> ara</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-pagination class="pull-right" aria-controls="myProtocolsTable" v-model="d_patientListTableData['current']" :total-rows="d_patientList.length" :per-page="d_patientListTableData['per-page']"></b-pagination>
                    <b-table id="searchPatientTable" :per-page="d_patientListTableData['per-page']" :current-page="d_patientListTableData['current']" thead-tr-class="" head-variant="dark" striped responsive stacked="sm" ref="ref_searchPatient" bordered small hover :items="d_patientList" :filter-included-fields="['firstname', 'lastname','record_id', 'personel_number', 'passport_number']" :fields="searchPatientColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-hovered="" @row-unhovered="">
                      <template v-slot:cell(actions)="row">
                        <b-dropdown variant="success">
                          <b-dropdown-item @click="f_selectFromPatient(row.item)">Hastayı FROM olarak seç</b-dropdown-item>
                          <b-dropdown-item @click="f_selectToPatient(row.item)">Hastayı TO olarak seç</b-dropdown-item>
                        </b-dropdown>
                      </template>
                      <template v-slot:cell(rowNumber)="row">
                        {{ ((d_patientListTableData['current'] - 1) * d_patientListTableData['per-page']) + row.index + 1 }}
                      </template>
                      <template v-slot:cell(name)="row">
                        {{ row.item.firstname.toLocaleLowerCase('tr') + ' ' + row.item.lastname.toLocaleLowerCase('tr') }}
                      </template>
                      <template v-slot:cell(sex)="row">
                        <i v-if="row.item.sex === '0'" class="fa fa-mars"></i>
                        <i v-else class="fa fa-venus"></i> {{ row.item.sex === '0' ? 'Erkek' : 'Kadın' }}
                      </template>
                      <template v-slot:cell(citizen_type)="row">
                        {{ row.item.citizen_type === 'citizen' ? 'Türkiye' : 'Yabancı' }}
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import { mapGetters } from "vuex";
import PatientService from '@/services/patient';
import HospitalService from '@/services/hospital';
import vSelect from 'vue-select';
import LineView from '@/components/chart/LineView';
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "MergePatient",
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      lang: "lang"
    })
  },
  data () {
    return {
      d_hospitalGroup: '',
      d_hospitalId: '',
      d_fromPatient: {},
      d_toPatient: {},
      d_hospitalGroupList: [],
      d_hospitalList: [],
      d_searchText: '',
      d_tempPatientList: [],
      d_patientList: [],
      d_patientListTableData: { 'current': 1, 'per-page': 50 },
      searchPatientColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "rowNumber",
        label: " "
      }, {
        key: "record_id",
        label: "WisdomEra Hasta No",
        sortable: true
      }, {
        key: "name",
        label: "Hasta Adı",
        sortable: true
      }, {
        key: "age",
        label: "yaş",
        sortable: true
      }, {
        key: "sex",
        label: "Cinsiyet",
        sortable: true
      }, {
        key: "personel_number",
        label: "Tc No",
        sortable: true
      }, {
        key: "passport_number",
        label: "Pasaport No"
      }, {
        key: "himsPatientID",
        label: "Medin Hasta No"
      }, {
        key: "citizen_type",
        label: "vatandaşlık",
        sortable: true
      }]
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_hospitalGroup = this.user.hospital_group;
    this.d_hospitalId = this.user.hospital_id;
    this.f_getHospitalGroup();
  },
  mounted: function () {},
  methods: {
    f_mergePatients: function () {
      if (!this.d_fromPatient || !this.d_fromPatient.id || !this.d_toPatient || !this.d_toPatient.id) {
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Kaynak ve hedef hastaların seçilmesi zorunludur', 'centered': true, 'title': 'Hasta Birleştirme İşlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else if (this.d_fromPatient.id === this.d_toPatient.id) {
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Kaynak ve hedef hastalar aynı hastalar olamaz', 'centered': true, 'title': 'Hasta Birleştirme İşlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        let modal_data = { 'type': 'confirm', 'text': 'Bu işlemi gerçekleştirmek istediğinize emin misiniz? Lütfen unutmayın, başlatılan işlem durdurulamaz ve geri alınamaz!!!', 'centered': true, 'title': 'Hasta Birleştirme İşlemi hakkında', 'confirm': false, 'function_name': 'f_mergePatientsConfirmed', 'arguments': [] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_mergePatientsConfirmed: function () {
      if (!this.d_fromPatient || !this.d_fromPatient.id || !this.d_toPatient || !this.d_toPatient.id) {
        alert('kaynak ve hedef hastalarin secilmesi zorunludur');
      } else if (this.d_fromPatient.id === this.d_toPatient.id) {
        alert('kaynak ve hedef hastalar ayni hastalar olamaz');
      } else {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta dataları birleştiriliyor.' } });
        PatientService.merge_patients({ 'from_patient_id': this.d_fromPatient.id, 'to_patient_id': this.d_toPatient.id })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_fromPatient = {};
              this.d_toPatient = {};
              this.d_patientList = [];
              this.d_tempPatientList = [];
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Birleştirme işlemi başarıyla gerçekleştirildi', 'centered': true, 'title': 'Hasta Birleştirme İşlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              this.d_fromPatient = {};
              this.d_toPatient = {};
              this.d_patientList = [];
              this.d_tempPatientList = [];
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Birleştirme esnasında bir problem oluştu, lütfen sistem yöneticisiyle irtibata geçiniz', 'centered': true, 'title': 'Hasta Birleştirme İşlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      }
    },
    f_selectFromPatient: function (row) {
      this.d_fromPatient = row;
    },
    f_selectToPatient: function (row) {
      this.d_toPatient = row;
    },
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_searchPatient();
      }
    },
    f_searchPatient: function () {
      if (this.d_searchText && this.d_searchText !== '') {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta listesi getiriliyor.' } });
        PatientService.search_from_patientlist({ 'search': this.d_searchText, 'document_key': 'hospital_patientlist_' + this.d_hospitalGroup + "_" + this.d_hospitalId, 'document_bucket': 'hospital' })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              this.d_patientList = resp.data.result;
              this.d_tempPatientList = JSON.parse(JSON.stringify(this.d_patientList));
            } else {
              this.d_patientList = [];
              this.d_tempPatientList = [];
            }
          });
      } else {
        alert('Lutfen arama kriteri giriniz.');
      }
    },
    f_changeHospitalGroup: function () {
      for (let i in this.d_hospitalGroupList) {
        if (this.d_hospitalGroupList[i].value === this.d_hospitalGroup) {
          this.d_hospitalList = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
          this.$forceUpdate();
          break;
        }
      }
    },
    f_getHospitalGroup: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = JSON.parse(JSON.stringify(resp.data.result));
            this.f_changeHospitalGroup();
          }
        });
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_mergePatientsConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_mergePatientsConfirmed();
          }
        }
      }
    }
  },
  components: {
    Modal,
    vSelect,
    LineView
  }
};

</script>

<style type="text/css">
canvas {
  background-color: white;
}

</style>

